.main {
  background-color: #f9faff;
  height: 100%;
  width: 100%;
  padding: 50px 90px;
}

.main__head-profile {
  font-size: 15px;
  font-weight: 600;
}

.main__head-name {
  /* font-size: 35px;
  font-weight: 600;
  color: var(--primary-color); */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  text-transform: capitalize;
  letter-spacing: 0.15px;
}

.main__head-mark {
  font-weight: 600;
  font-size: 16px;
  color: var(--primary-color);
}

.main__tab button {
  cursor: pointer;
  background-color: var(--primary-color);
  border: none;
  outline: none;
  height: 35px;
  width: 170px;
  border-radius: 5px;
  color: var(--color-white);
  font-family: inherit;
  font-weight: 600;

  display: flex;
  align-items: center;
  justify-content: center;
}

.top-padding{
  padding-top: 12px;
}

.main__changepass-form {
  width: 350px;
}

.main__inputbox {
  width: 100%;
  margin-bottom: 15px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.main__inputbox input {
  width: 90%;
  font-family: inherit;
  font-size: 12px;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  outline: none;
  background-color: #fff;
  padding: 9px;
  color: #7a7a7a;
  border: 2px solid #9595953b;
}

.main__changepass-form button {
  margin-top: 22px;
  width: 90%;
  padding: 8px;
  border: none;
  outline: none;
  font-family: inherit;
  font-weight: 500;
  font-size: 14px;
  color: var(--color-white);
  border-radius: 5px;
  background-color: var(--primary-color);
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  .main {
    padding: 10px;
  }
}
