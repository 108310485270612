.newpassword {
    height: 100%;
    width: 100%;
    background-image: url('../images/background-banner.png');
    background-repeat: no-repeat;
    height: calc(100vh - 60px);
    background-size: 100% 100%;
    display: flex;
    justify-content:center;
  }
  
  .main__head-profile {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
  }
  
  .uname {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    /* font-size: 35px;
    font-weight: 600;
    color: var(--primary-color); */
    text-transform: capitalize;
  }
  
  .main__head-mark {
    font-weight: 600;
    font-size: 16px;
    color: var(--primary-color);
  }
  
  .main__tab button {
    cursor: pointer;
    background-color: var(--primary-color);
    border: none;
    outline: none;
    height: 35px;
    width: 170px;
    border-radius: 5px;
    color: var(--color-white);
    font-family: inherit;
    font-weight: 600;
  
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .main__changepass-form {
    width: 350px;
  }
  
  .main__inputbox {
    width: 100%;
    margin-bottom: 15px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  
  .main__inputbox input {
    width: 90%;
    font-family: inherit;
    font-size: 12px;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: #fff;
    padding: 9px;
    color: #7a7a7a;
    border: 2px solid #9595953b;
  }
  
  .main__changepass-form button {
    width: 90%;
    padding: 8px;
    border: none;
    outline: none;
    font-family: inherit;
    font-weight: 500;
    font-size: 14px;
    color: var(--color-white);
    border-radius: 5px;
    background-color: var(--primary-color);
    cursor: pointer;
  }
  @media screen and (max-width: 800px) {
    .main {
      padding: 10px;
    }
  }
  
  .resetPassword{
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    width: 408px;
    height: 335px;
    padding: 28px;
    border-radius: 4px;
    position: relative;
    top:242px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  }
  
  .updatePassword{
    display:flex;
    gap: 12px;
    margin-top: 10px;
    width: 320px !important;
  }
  
  .inputHeight{
    height:75px
  }
  
  .forgetPassSparks{
    position: fixed;
    right: 37%;
    bottom: 10%;
  }
  
  .line-shape{
    position: fixed;
    left: 63.5%;
    bottom: 36%;
  
  }
  
  .green-cricle{
    position: fixed;
      bottom: 5%;
      right: 0%;
  }
  
  .semi-circle{
    position: fixed;
    top: 15%;
    left:-60px;
  }
  .sparks{
    position: fixed;
    right: 6%;
    bottom: 2%;
  }

  @media only screen   
and (max-width: 1378px){
  .semi-circle{
    top: 15%;
    left:-60px;
  } 
  .line-shape {
    left: 60.5%;
    bottom: 32%;
}
}

.center {
    position: absolute;
    left: 48%;
    top: 56%;
    transform: translate(-50%, -50%);
}
@media only screen   
and (min-width: 1378px)and (max-width:1605px){
  

}


@media only screen and (min-width:1608px){
 

}