.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.navbar {
  height: 60px;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0 90px;
}

.hero {
  height: 100%;
  min-height: 650px;
  display: flex;
  background-image: linear-gradient(to right, #f6eacc15, #fa757510, #00ffbb11);
  /* padding: 0px 120px; */
}

@media screen and (max-height: 700px) {
  .container {
    overflow: scroll;
  }
}

.hero__left {
  width: 50%;
  height: 100%;
  /* background-color: red; */
  padding: 80px 95px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero__full {
  width: 100%;
  height: 100%;
  /* background-color: red; */
  padding: 80px 95px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.left__head p {
  display: flex;
  flex-direction: column;
  font-size: 35px;
  font-weight: 600;
}

.left__title {
  margin-top: 25px;
}

.left__title p {
  font-size: 18px;
  font-weight: 500;
}

.title1 {
  opacity: 0;
  transform: translateY(-20px);
  animation: fadeTitle1 1s 0.1s forwards ease-in-out;
}

.title2 {
  opacity: 0;
  transform: translateY(-20px);
  animation: fadeTitle1 1s 0.8s forwards ease-in-out;
}

.title3 {
  opacity: 0;
  transform: translateY(-20px);
  animation: fadeTitle1 0.5s 1.5s forwards ease-in-out;
}

@keyframes fadeTitle1 {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.left__subtitle {
  margin-top: 30px;
}

.left__subtitle-1 {
  font-size: 20px;
  font-weight: 600;
}

.left__subtitle-2 {
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  /* margin: 25px 0; */
}

.typewriter-text {
  margin: 20px 0;
  display: inline-block;
  overflow: hidden;
  /* letter-spacing: 2px; */
  animation: typing 5s steps(50, end), blink 0.75s step-end infinite;
  white-space: nowrap;
  /* font-size: 30px; */
  font-weight: 700;
  border-right: 4px solid var(--primary-color);
  box-sizing: border-box;
}

@keyframes typing {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

@keyframes blink {

  from,
  to {
    border-color: transparent;
  }

  50% {
    border-color: var(--primary-color);
  }
}

.left__action {
  width: auto;
  /* margin-top: 38px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: flex-start; */
  /* text-align: center; */
}

.left__action button {
  cursor: pointer;
  background-color: var(--primary-color);
  border: none;
  outline: none;
  height: 48px;
  width: 210px;
  border-radius: 30px;
  color: var(--color-white);
  font-family: inherit;
  font-weight: 600;

  display: flex;
  align-items: center;
  justify-content: center;
}

.left__action-offer {
  margin-top: 7px;
  font-size: 10px;
  /* text-align: center; */
  font-style: italic;
  font-weight: 500;
}

.hero__right {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: yellow; */
}

.home-container {
  width: 100%;
  height: auto;
  /* overflow: hidden; */
}

.home-navbar {
  height: 60px;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.navbar-bell {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-bell::before {
  content: "";
  position: absolute;
  top: -3px;
  right: -1px;
  height: 7px;
  width: 7px;
  background-color: red;
  z-index: 100;
  border-radius: 50%;
}

.navbar__menu {
  display: flex;
  width: 90px;
  justify-content: space-between;
  align-items: center;
}

.navbar__profile {
  /* position: relative; */
  width: 48px;
  height: 48px;
  border-radius: 50%;
  /* background-color: var(--purpol-color); */
  background-color: #EDE7F6;
  /* color: var(--color-white); */
  color: var(--purpol-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.navbar__popup-menu {
  color: #000;
  position: absolute;
  top: 52px;
  right: 14px;
  width: 200px;
  /* height: 200px; */
  padding: 15px 10px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 10;
  border-radius: 10px;

  display: none;
}

.navbar__popup-menu ul {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.navbar__popup-menu ul li {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  /* background-color: red; */
  padding: 10px;
  transition: all 0.2s ease-in-out;
}

.navbar__popup-menu ul li:hover {
  background-color: var(--primary-color);
  color: var(--color-white);
  border-radius: 10px;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 40px;
}

.company-name {
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 34px;
  line-height: 123.5%;
  color: rgba(0, 0, 0, 0.87);
  padding: 30px 0;
  letter-spacing: 0.25px;
}

.info-box {
  display: flex;
  padding-bottom: 30px;
}

.info-iner-box {
  width: calc(100% / 5);
  background: #ffffff;
  border: 2px solid #fafafa;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 3%);
  border-radius: 8px;
  margin-right: 20px;
  padding: 10px;
}

.info-iner-box:last-child {
  margin-right: 0;
}

.info-icon {
  display: flex;
  align-items: center;
  height: 35px;
  width: 35px;
  justify-content: center;

  border-radius: 50%;
}

.rec-icon {
  background: #e8eaf6;
}

.clip-icon {
  background: #fff8e1;
}

.tick-icon {
  background: #e8f5e9;
}

.arrow-icon {
  background: #fbe9e7;
}

.eye-icon {
  background: #e1f5fe;
}

.info-detail {
  padding: 10px 0;
  min-height: 60px;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Roboto';
  font-style: normal;
  letter-spacing: 0.17px;
}

.info-number {
  font-weight: 800;
  font-size: 30px;
  line-height: 133.4%;
  font-family: 'Roboto';
  font-style: normal;

}

.number-color {
  color: rgba(0, 0, 0, 0.87);
}

.rs-number {
  color: #f44336;
}

.company-calender-year {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
}

.finance-year-text {
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  color: rgba(0, 0, 0, 0.6);
  padding-right: 20px;
  font-family: 'Roboto';
}

.download-btn {
  background: #673ab7 !important;
  /* Elevation/2 */
  color: #fff !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12) !important;
  border-radius: 4px !important;
  margin-left: 30px !important;
  font-weight: 500;
  font-size: 13px !important;
  line-height: 22px !important;
  letter-spacing: 0.46px !important;
}

.calender-section {
  display: inline-block;
  width: 100%;
}

.calender-box {
  display: inline-block;
  width: calc(100% / 4 - 12px);
  background: #ffffff;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 3%);
  border-radius: 16px;
  margin-right: 15px;
  border: 1px solid #ede7f6;
  /* padding: 20px; */
  margin-bottom: 20px;
  vertical-align: top;
}

.calender-box:nth-child(4),
.calender-box:nth-child(8),
.calender-box:nth-child(12),
.calender-box:nth-child(16),
.calender-box:nth-child(20) {
  margin-right: 0;
}

.calender-header {
  display: flex;
  padding: 15px 20px;
  background: #ede7f6;
  border: 1px solid #ede7f6;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.calander-info {
  padding: 25px;
}

.calnder-info-iner {
  display: flex;
  padding-bottom: 15px;
  align-items: center;
}

.clander-month {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.15px;
}

.calnder-date {
  font-family: Roboto, sans-serif;
  padding-right: 15px;
  font-weight: 700;
  font-size: 20px;
  line-height: 160%;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.6);
}

#calnder-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.6);
}

.clander-download-btn {
  border: 1px solid #7e57c2;
  border-radius: 4px;
  padding: 4px 25px;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
}

.custom-popup>div:first-child {
  background: rgba(71, 77, 102, 0.64) !important;
}

.group-btn {
  padding: 10px 35px 20px !important;
}

.cander-header {
  padding-bottom: 15px !important;
}

.cander-header,
.cander-body {
  display: flex;
  padding: 15px 10px 0 10px;
  align-items: center;
}

.clander-header-inr,
.clander-body-inr {
  font-family: 'Roboto', sans-serif;
  width: calc(100% / 5);
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.17px;
}

.cander-body {
  border-top: 1px solid #e0e0e0;
}

.clander-body-inr {
  white-space: pre-line;
}

.clander-hedding {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  border-right: 2px solid #e0e0e0;
  letter-spacing: 0.17px;
}

.clander-hedding:last-child {
  border-right: 0;
}

.cancel-btn {
  border: 1px solid #673ab7 !important;
  border-radius: 4px !important;
  color: #673ab7 !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 22px !important;
}

.attched-btn {
  border: 1px solid #673ab7 !important;
  border-radius: 4px !important;
  color: #673ab7 !important;
  font-size: 13px !important;
  padding: 3px 8px !important;
}

.save-btn {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12) !important;
  border-radius: 4px !important;
  background: #673ab7 !important;
  color: #fff !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 22px;
}

.clander-body-inr .clander-spacce {
  flex-direction: column !important;
}

.clander-body-inr .clander-spacce .chip-btn {
  margin-bottom: 5px;
  margin-left: 0;
  justify-content: unset;
}

/* .chip-btn:hover{
  overflow: hidden;
    text-overflow: unset;
    padding-left: 12px;
    padding-right: 12px;
    white-space: nowrap;
} */

@media screen and (max-width: 800px) {
  .container {
    overflow: auto;
    height: 100%;
  }

  .navbar {
    justify-content: center;
  }

  .home-navbar {
    padding: 20px;
  }

  .navbar__popup-menu {
    right: 10px;
  }

  .hero {
    flex-direction: column-reverse;
    height: auto;
  }

  .hero__left {
    width: 100%;
    padding: 10px;
  }

  .hero__right {
    width: 100%;
    padding: 10px;
  }

  .left__head-1 {
    font-size: 22px;
  }

  .left__head-2 {
    font-size: 22px;
  }

  .left__title p {
    font-size: 13px;
  }

  .left__title {
    margin-top: 10px;
  }

  .typewriter-text {
    margin: 12px 0;
    animation: none;
    white-space: normal;
    border: none;
  }

  .left__subtitle {
    margin-top: 12px;
  }

  .left__subtitle-1 {
    font-size: 15px;
  }

  .left__subtitle-2 {
    font-size: 14px;
  }
}

.tooltiptext {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.4px;

  color: #D32F2F !important;
  margin-bottom: 5px;
}

.inactivebox {
  background-color: rgb(204, 204, 204) !important;
}

.pending {
  color: #D32F2F !important;
}

.completed {
  color: #2E7D32 !important;
}

.na {
  color: gray !important;
}

.css-1e6alsu-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
  position: absolute;
  left: 82%
}

.disable-action {

  pointer-events: none;
}


.spinner {
  animation: spin infinite 5s linear;

  /*You can increase or decrease the timer (5s) to 
   increase or decrease the speed of the spinner*/
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  color: transparent;
  cursor: pointer;
  height: auto;
  width: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}