input,
input::placeholder {
  font-size: 12px;
  color: #8f95b2;
}
.auth-container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
}
.login-auth {
  width: 100%;
  height: 100%;
}
.auth-Header {
  display: flex;
  width: 100%;
  padding: 10px;
}
.forgot-page {
  margin-top: 75px !important;
  margin-bottom: 102px !important;
}
.logo-icon {
  display: flex;
  width: 126px;
}
.logo-icon img {
  width: 100%;
  max-width: 100%;
}
.gst-in {
  padding-bottom: 20px;
  margin-top: 5px;
  font-weight: 500;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  /* identical to box height, or 32px */

  letter-spacing: 0.15px;
}
.user-page,
.verfiy-email {
  background-image: url(../images/background-banner.png);
  background-repeat: no-repeat;
  /* height: calc(100vh - 61px); */
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 130px;
  
}
.gst-page.mr-auto {
  padding-bottom: 45px;
}

.login-panel {
  background-image: url(../images/background-banner.png);
  background-repeat: no-repeat;
  /* height: calc(100vh - 61px); */
  background-size: 100% 100%;
  padding-top: 100px;
  padding-bottom: 130px;
}
@media all and (min-width: 1600px) and (max-width: 1920px) {
  .verfiy-email,
  .forgot-password {
    height: calc(100vh - 61px);
    background-size: 100% 100%;
  }
}
@media all and (min-width: 1440px) and (max-width: 1599px) {
  .login-panel,
  .verfiy-email,
  .user-page {
    /* height: calc(100vh - 61px); */
    background-size: 100% 100%;
  }
}
@media all and (min-width: 1200px) and (max-width: 2500px) {
  .forgot-password,
  .login-panel {
    height: calc(100vh - 61px);
    background-size: 100% 100%;
  }
}

@media all and (min-width: 1025px) and (max-width: 1339px) {
  .login-panel,
  .verfiy-email,
  .forgot-password {
    height: calc(100vh - 61px);
    background-size: 100% 100%;
  }
}
@media all and (max-width: 5000px) {
  /* .login-panel,
  .verfiy-email,
  .user-page {
    height: calc(100vh - 61px);
    background-size: 100% 100%;
  } */
}
/* .verify-page p {
  color: #fff;
} */

.auto-complte .MuiChip-deletable {
  color: rgb(255 255 255) !important;
  background-color: rgb(103 58 183) !important;
}
.auto-complte svg {
  color: rgb(220 220 220 / 68%) !important;
}
.gst-page {
  /* height: calc(100vh - 61px); */
  background-size: 100% 100%;
}
.left {
  position: relative;
  width: 50%;
  /* padding: 0px 100px; */
  /* background-color: var(--primary-color); */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-items: center; */
  /* min-height: 650px; */
}
.complaine-text {
  font-weight: 600;
  font-size: 60px;
  color: #ffffff;
  letter-spacing: -0.5px;
  line-height: 120%;
  padding-bottom: 20px;
}
.complaine-sub-text {
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.15px;
  line-height: 160%;
  color: #ffffff;
  width: 80%;
}
.left__info {
  margin: 36px 0;
  padding: 30px 30px;

  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
}

.left__info p {
  font-size: 24px;
  margin-bottom: 14px;
  color: var(--color-white);
}

.left__info-content p {
  margin-bottom: 20px;
  color: var(--color-white);
  font-weight: 500;
  font-size: 15px;
}

.left__bottom {
  color: var(--color-white);
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, -20px);
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  width: 50%;
}

.left__bottom p {
  font-size: 14px;
  cursor: pointer;
}

.right {
  flex-grow: 1;

  display: flex;
  justify-content: center;
  align-items: center;
}

.right__wrap {
  /* background-color: red; */
  width: 400px;
  height: 100%;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/*
@media screen and (max-height: 600px) {
  .right__form {
    overflow: scroll;
  }
} */
.right-section-wrp {
  width: 50%;
  position: relative;
}

.right__form,
.verify-page {
  position: relative;
  width: 408px;
  background: #fff;
  padding: 25px;
  border-radius: 4px;
  /* box-shadow: 0px 12px 40px rgba(16, 24, 64, 0.24); */
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  margin: auto;
}
.right-section-wrp {
  /* background: url("../images/s1.png"); */
}

.login-text {
  padding-bottom: 20px;
}
.login-main-text {
  font-weight: 600;
  font-size: 24px;
  line-height: 133.4%;
  color: rgba(0, 0, 0, 0.87);
}
.login-sub-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto";
  font-style: normal;
}
.right__form-wrapp::-webkit-scrollbar {
  appearance: none;
}
.close-icon svg {
  fill: #c3bcbc;
  cursor: pointer;
}

@media screen and (max-height: 500px) {
  .right__form-wrapp {
    display: block;
  }
}

.right__form p {
  text-align: center;
  /* margin-bottom: 12px; */
  font-weight: 600;
}
.form__input-box {
  width: 100%;
  /* password not match UI fix */
  padding-top: 10px;

  /* margin-bottom: 15px;
  overflow: hidden;
  border-radius: 5px;
  border: 2px solid #9595953b;
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px; */
}
.form__input-box input {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  border: none;
  outline: none;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto";
}
.input-field {
  width: 100%;
}
.forgot-text {
  /* font-size: 14px;
  text-align: right;
  margin-bottom: 12px;
  font-weight: 600;
  cursor: pointer; */
  padding-left: 5px;
}
.not-registrer {
  margin-top: 20px;
}
.not-text {
  padding-right: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #101840;
}
.form-control {
  margin-bottom: 15px;
  /* width: 344px;
  margin-top: 30px; */
}

.right__form .business_form-submit-btn {
  margin-top: 20px;
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  font-family: inherit;
  font-weight: 500;
  font-size: 15px;
  color: var(--color-white);
  border-radius: 4px;
  background-color: var(--purpol-color);
  cursor: pointer;
  font-family: "Roboto";
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
}
.profile-btn {
  /* margin-top: 20px !important; */
  width: 100% !important;
  padding: 10px !important;
  border: none !important;
  outline: none !important;
  font-family: inherit !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  color: var(--color-white) !important;
  border-radius: 4px !important;
  background-color: var(--purpol-color) !important;
  cursor: pointer !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
}
.right__form button:hover {
  background-color: var(--purpol-color) !important;
}
.signup_terms {
  /* padding: 0 10px; */
  display: flex;
  align-items: center;
}
.signup_terms-text {
  text-align: left !important;
  margin-left: 5px;
  font-weight: 400 !important;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto";
  line-height: 150%;
  letter-spacing: 0.15px;
}
.check-box-m {
  padding: 5px !important;
}
.signup_terms-text a {
  padding-left: 5px;
}
.btn-group {
  display: flex;
}
.skip-btn {
  font-size: 15px !important;
  /* line-height: 26px;
  letter-spacing: 0.46px; */

  width: 100%;
  margin-top: 20px !important;
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87) !important;
  border: 1px solid rgba(0, 0, 0, 0.04);
  margin-right: 10px !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.skip-btn:hover {
  color: #fff !important;
}
.acount-detail {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.5px;
  color: #FFFFFF;

  margin: 0 auto;
  width: 60%;
  /* padding-top: 45px; */
  padding-bottom: 65px;
}
.mr-auto {
  margin: 0 auto;
  /* padding-top: 70px; */
}
.or-border {
  margin: 15px 0;
}
.input__box input:disabled {
  background-color: #f5f5f5;
}
.setup-num-text {
  /* background: #edeff5; */
  /* margin-right: 23px; */
  /* padding: 0px 8px; */
  display: flex;
  align-items: center;
  /* color: #474d66; */
  /* font-size: 12px; */
}
.setup-num-text:nth-child(2) {
  padding-left: 10px;
}
.setup-num-text:nth-child(3) {
  padding-left: 10px;
}

.third-step {
  color: rgba(0, 0, 0, 0.6) !important;
}
.active-page {
  /* background: #d6e0ff; */
  color: #2952cc;
  font-weight: 600;
  border-radius: 0;
}
.gst-name {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  font-size: 14px;
  font-family: "Roboto";
  line-height: 157%;
  letter-spacing: 0.1px;
  padding-left: 5px;
}
.gst-number {
  color: #474d66;
  font-size: 14px;
}
.setup-number {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}
.mb-b {
  margin-bottom: 0 !important;
}
.setup-num-tick {
  background: #dcf2ea;
  margin-right: 23px;
  padding: 2px 3px;
  display: flex;
  align-items: center;
  color: #52bd94;
  border-radius: 3px;
}

@font-face {
  font-family: "SF Pro Text-1";
  src: url("//db.onlinewebfonts.com/t/1bd3de6fcdd9ba7750e5a353dc6ef6e3.eot");
  src: url("//db.onlinewebfonts.com/t/1bd3de6fcdd9ba7750e5a353dc6ef6e3.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/1bd3de6fcdd9ba7750e5a353dc6ef6e3.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/1bd3de6fcdd9ba7750e5a353dc6ef6e3.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/1bd3de6fcdd9ba7750e5a353dc6ef6e3.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/1bd3de6fcdd9ba7750e5a353dc6ef6e3.svg#SF Pro Text")
      format("svg");
}

@font-face {
  font-family: "SF Pro Text";
  font-weight: 500;
  src: url("../SF-Pro-Text-Medium.otf");
}

.or-border p {
  font-weight: 500;
  font-size: 14px;
  position: relative;
  color: #101840;
  text-align: left;
  font-family: "SF Pro Text", "SF Pro Text-1", system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;
  /* font-family: system-ui; */
  font-style: normal;
  line-height: 24px;
}

.or-border p::before {
  content: "";
  position: absolute;
  right: 150%;
  top: 50%;
  width: 90px;
  height: 1.5px;
  background-color: #9595953b;
}
.or-border p::after {
  content: "";
  position: absolute;
  left: 150%;
  top: 50%;
  width: 90px;
  height: 1.5px;
  background-color: #9595953b;
}

.google_signin-btn {
  width: 100%;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.12);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Roboto";
}

.google_signin-btn span {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 400;
  margin-left: 10px;
  letter-spacing: 0.15px;
  font-family: "Roboto";
}

.nam-text {
  font-size: 13px;
  color: var(--color-black);
  font-weight: 600;
  margin: 15px 0;
}

.signup-text {
  font-weight: 400;
  font-size: 14px;
  color: #1976d2;
  cursor: pointer;
  text-decoration: underline;
  font-family: "Roboto";
  line-height: 143%;
}
.footer-text {
  font-size: 13px;
  position: absolute;
  bottom: 12px;
  color: #aeaeae;
}
.label-text {
  font-size: 12px;
  padding-top: 5px;
  display: block;
  color: #474d66;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

@media screen and (max-width: 800px) {
  .left {
    display: none;
  }
  .right__form {
    width: 98%;
  }
  .right-section-wrp {
    width: 100%;
  }

  .right__wrap {
    width: 85%;
  }
  .verify-page {
    text-align: center;
    width: 90%;
  }

  .right__form-wrapp {
    /* padding: 0 20px; */
  }
  .gst-page {
    height: auto;
    padding-bottom: 135px !important;
  }
  .user-page {
    /* height: 91vh; */
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .right__form .business_form-submit-btn {
    font-size: 10px;
  }
  .skip-btn {
    font-size: 11px !important;
  }
}

@media only screen and (min-width: 1080px) {
  .complaine-text {
    font-size: 35px;
  }
  .left {
    padding: 0px 50px;
  }
}

@media only screen and (max-width: 1378px) {
  .complaine-text {
    font-size: 40px;
  }
  .left {
    padding: 0px 100px;
  }
  .right__form {
    width: 370px;
  }
}

@media only screen and (min-width: 1378px) and (max-width: 1605px) {
  .complaine-text {
    font-size: 50px;
  }
  .left {
    padding: 0px 90px;
  }
  .right__form {
    width: 385px;
  }
  .line-shape {
    left: 62.5%;
    bottom: 35%;
  }
}

@media only screen and (min-width: 1608px) {
  .complaine-text {
    font-size: 60px;
  }
  .left {
    padding: 0px 115px;
  }
}

.line-shape {
  position: fixed;
  left: 63.5%;
  bottom: 36%;
}

.green-cricle {
  position: fixed;
  bottom: 5%;
  right: 0%;
}

.semi-circle {
  position: fixed;
  top: 15%;
  left: -60px;
}
.sparks {
  position: fixed;
  right: 6%;
  bottom: 2%;
}

.forgetPassSparks {
  position: fixed;
  right: 37%;
  bottom: 10%;
  height: 130%;
}

/* to hide the blank space at the bottom on verify page  */
.verfiy-email{
  padding: 187.5px;
}