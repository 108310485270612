@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;400;500;600;700;800&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
}

body {
  /* font-family: "Poppins", sans-serif; */
  font-family: "Plus Jakarta Sans";
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

:root {
  --primary-color: #673ab7;
  --color-white: #fff;
  --color-black: #362e2e;
  --back-color: #000000;
  --blue-color: #0051ff;
  --purpol-color: #673ab7;
}
.root {
  /* height: 100vh; */
  width: 100%;
  /* background-color: red; */
}

.Calendar {
}

.float-calender {
  position: absolute !important;
  right: 0px !important;
  top: 30px;
}

textarea {
  padding: 15px;
  width: 349px;
  height: 110px;
}
