.form-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  display: flex;
  justify-content: center;
}
.tick-color {
  fill: #673ab7 !important;
}
.number-text {
  width: 24px;
  height: 24px;
  background: rgba(0, 0, 0, 0.38);
  border-radius: 64px;
  text-align: center;
  color: #ffffff;
  line-height: 217%;
  font-size: 12px;
}
.bussines--panel {
  width: 506px;
  background: #fff;
  padding: 25px;
  border-radius: 4px;
  /* box-shadow: 0px 12px 40px rgb(16 24 64 / 24%); */
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px rgb(0 0 0 / 14%),
    0px 1px 3px rgb(0 0 0 / 12%);
  margin: auto;
  /* background: #fff url(../images/shadow.png);
  /* background-position: -129px; */
  /* background-position: -128px;
  background-repeat: no-repeat; 
  background-size: 100% 110%; */
}
.business_form {
  /* width: 450px; */
  /* background-color: red; */
  height: auto;
  display: flex;
  flex-direction: column;
  /* padding: 20px 0; */
}

.form-container::-webkit-scrollbar {
  width: 10px;
  background-color: lightgrey;
  border-radius: 10px;
}

.form-container::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 10px;
}

.close-required {
  width: fit-content !important;
  display: flex;
  align-items: center;
}

.right {
  /* flex-grow: 1; */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input__box {
  width: 100%;
  display: flex;
  align-items: center;
  /* margin-bottom: 10px; */
}

.input-required {
  /* background-color: red; */
  margin-left: 5px;
  font-size: 18px;
  font-weight: bold;
  color: red;
  display: flex;
  align-items: center;
}

.small-required {
  font-size: 16px;
}

.css-12jo7m5,
.css-xb97g8 {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

.css-xb97g8 {
  border-radius: 0 !important;
}

.small-text {
  display: flex;
}
.input__box input {
  width: 100%;
  font-family: inherit;
  font-size: 12px;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  outline: none;
  background-color: #fff;
  padding: 9px;
  color: #7a7a7a;
  border: 2px solid #9595953b;
}
.input__box textarea {
  width: 90%;
  font-family: inherit;
  font-size: 12px;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  outline: none;
  background-color: #fff;
  padding: 9px;
  color: #7a7a7a;
  border: 2px solid #9595953b;
  height: 100px;
}

.multi__form {
  /* margin-top: 15px; */
}

.multi_field {
  display: flex;
  width: 100%;
  margin-top: 5px;
  justify-content: space-between;
  /* background-color: red; */
}

.multi_input-box {
  display: flex;
  align-items: center;
  width: 49%;
}

.select-input {
  width: 100% !important;
  /* padding:6px 0px !important; */
  font-family: inherit !important;
  font-size: 12px !important;
  font-weight: 500;
}
.mb-15 {
  margin-bottom: 15px;
}
.google-text {
  color: rgba(0, 0, 0, 0.54);
}
.multi-select {
  width: 100% !important;
  font-family: inherit !important;
  font-size: 12px !important;
  font-weight: 500;
}
.input-info {
  font-size: 11px;
  color: #6e6b6c;
  margin-bottom: 15px;
}
.form-head-label {
  /* margin-top: 10px; */
  margin-left: 2px;
  font-size: 13px;
  font-weight: 500;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.css-tlfecz-indicatorContainer svg {
  background-color: #a8a8a8;
  border-radius: 50%;
  color: #fff;
}

.form__multi-btns {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.form__multi-btns div {
  border: 2px solid #9595953b;
  border-radius: 5px;
  margin-right: 15px;
  /* width: 70px; */
  padding: 5px 15px;
  font-family: inherit;
  font-size: 12px;
  font-weight: 500;
  color: #7a7a7a;
  margin-bottom: 10px;
  cursor: pointer;
}

.business_form-submit-btn {
  margin-top: 50px;
  width: 95%;
  padding: 8px;
  border: none;
  outline: none;
  font-family: inherit;
  font-weight: 500;
  font-size: 14px;
  color: var(--color-white);
  border-radius: 5px;
  background-color: var(--primary-color);
  cursor: pointer;
}

.selected-emp {
  border: 2px solid var(--primary-color) !important;
  background-color: #fff !important;
  color: #000 !important;
}

.css-yk16xz-control {
  border: 2px solid #9595953b !important;
}

.update-form-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.update-form {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.box1>.form-control, .box2>.form-control{
  margin-top: 30px;
}
.box1 {
  width: 50%;
  margin-right: 35px;
}
.box2 {
  width: 50%;
  /* padding-left: 10px; */
}
.box3 {
  /* width: 31.6%; */
  width: 28%;
}

.update-form-btn {
  margin-top: 40px;
  width: 100%;
  padding: 8px;
  border: none;
  outline: none;
  font-family: inherit;
  font-weight: 500;
  font-size: 14px;
  color: var(--color-white);
  border-radius: 5px;
  background-color: var(--primary-color);
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .main {
    height: auto;
  }
  .update-container {
    overflow: auto !important;
    height: auto;
  }
  .update-form {
    flex-direction: column;
    /* width: 100%; */
  }

  .update-form .box1,
  .box2,
  .box3 {
    width: 100%;
  }

  .multi_field {
    /* width: 9%; */
  }
  .form__multi-btns div {
    margin-right: 10px;
  }

  .business_form {
    width: 100%;
  }
  .bussines--panel {
    width: 98%;
  }
  .gst-name {
    font-size: 10px;
    line-height: 1;
    font-family: 'Roboto';
  }
  .number-text {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }
  .acount-detail {
    width: 95%;
  }
}
