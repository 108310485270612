.right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.right11{
  /* height: calc(100vh - 61px)!important; */
}
.personal_form {
  width: 450px;
  /* background-color: red; */

  display: flex;
  flex-direction: column;
}

.phone-input-box {
  /* display: flex; */
  align-items: center;
  /* background-color: red; */
}

.phone-input-box-wrap {
  width: 100%;
  margin-bottom: 15px;
  overflow: hidden;
  border-radius: 5px;
  border: 2px solid #9595953b;
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  padding: 9px;
}
.phone-input-box select {
  width: 15%;
  border: none;
  outline: none;
  font-family: inherit;
  font-weight: 500;
  color: #7a7a7a;
}

.phone-input-box-wrap input {
  padding: 0 2px;
  width: 100%;
  font-family: inherit;
  font-size: 12px;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  outline: none;
  background-color: #fff;
  color: #7a7a7a;
}

.personal-check {
  display: flex;
  margin-left: 3px;
  align-items: center;
}

.personal-check p {
  font-size: 13px;
  font-weight: 500;
  margin-left: 5px;
}
.personal-form-btn {
  margin-top: 10px;
  width: 95%;
  padding: 8px;
  border: none;
  outline: none;
  font-family: inherit;
  font-weight: 500;
  font-size: 14px;
  color: var(--color-white);
  border-radius: 5px;
  background-color: var(--primary-color);
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .personal_form {
    width: 90%;
  }
}

.line-shapes{
  position: fixed;
  left: 77.5%;
  bottom: 23%;

}

.green-cricle{
  position: fixed;
    bottom: 5%;
    right: 0%;
}

.semi-circle{
  position: fixed;
  top: 15%;
  left: -2.5%;
}
.sparks{
  position: fixed;
  right: 6%;
  bottom: 2%;
}

.center {
  position: absolute;
  left: 48%;
  top:60%;
  transform: translate(-50%, -50%);
}
.gstin{
  padding-bottom:0px !important
}