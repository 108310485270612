.main__tab {
  width: 25%;
  height: fit-content;
  padding-bottom: 20px;
  background-color: var(--color-white);
  border-radius: 10px;
  margin-right: 30px;
}

.profile-form,
.update-form-container {
  width: 75%;
  display: flex;
  justify-content: left;
  background: #fff;
  padding: 32px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px rgb(0 0 0 / 14%),
    0px 1px 3px rgb(0 0 0 / 12%);
  border-radius: 4px;
}
.account-tabing {
  padding: 10px !important;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  padding-left: 77px !important;
}
.tab-hedding {
  font-family: 'Roboto';
  letter-spacing: 0.1px;

  padding-left: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 157%;
  color: rgba(0, 0, 0, 0.87);
}
.tabactive {
  background: #ede7f6;
  color: #673ab7;
  cursor: pointer;
}
.tabactive svg path {
  fill: #673ab7 !important;
}
.account-tabing svg path {
  fill: #000;
}
.profile-form-wrap {
  /* width: 65%; */
  width: 344px;
}
.profile-form-wrap > .form-control{
  margin-top: 30px;
}
.form-hedding {
  font-family: 'Roboto';
  line-height: 160%;
  letter-spacing: 0.15px;

  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 20px;
}
.account-seeting {
  font-family: 'Roboto';
  letter-spacing: 0.15px;

  color: #000000de;
  padding: 18px 30px;
  width: 100% !important;
  display: flex;
  text-align: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
}

.verified-input p {
  font-size: 10px;
}

.profile-btn{
  font-family: 'Roboto', sans-serif !important;
  font-weight: 500 !important;
  font-size: 15px !important;

  margin-top: 10px;
  width: 344px !important;
  padding: 8px;
  border: none;
  outline: none;
  color: var(--color-white);
  border-radius: 5px;
  background-color: var(--primary-color);
  cursor: pointer;
}

.invite-profile-btn {
  height: 30px;
  width: 125px;
  background: #673ab7 !important;
  /* Elevation/2 */
  color: #fff !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12) !important;
  border-radius: 4px !important;
  font-weight: 500;
  font-size: 13px !important;
  line-height: 22px !important;
  letter-spacing: 0.46px !important;
}

.update-form-container>.profile-btn{
  width: 100% !important;

}
.main__tab-container {
  display: flex;
  /* padding-top: 30px; */
}

@media screen and (max-width: 800px) {
  .profile-form {
    width: auto;
  }

  .main__tab {
    width: 50%;
    /* justify-content: space-between; */
  }

  .main__tab div {
    margin-right: 20px;
    font-size: 12px;
    padding: 8px 18px;
  }
}

.team-email-link{
  text-decoration: underline;
  color: #1976D2;
  letter-spacing: 0.15px;
  /* font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px; */
  line-height: 150%;
}
